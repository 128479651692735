import * as React from 'react';
import Box from '@material-ui/core/Box';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


import MenuIcon from '@material-ui/icons/Menu';

import './style.css';
import Logo from '../../Imagens/icon.png';

/*Icones*/

import Inicio from '@material-ui/icons/HomeOutlined';
import Servicos from '@material-ui/icons/RoomService';
import Sobre from '@material-ui/icons/Info';
import Time from '@material-ui/icons/Group';
import Contato from '@material-ui/icons/PermPhoneMsg';
import Parceiro from '@material-ui/icons/Person';
import { Link } from 'react-router-dom';

export default function SwipeableTemporaryDrawer() {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const menu = [
        {
            name: 'Início',
            path: '',
            icon: Inicio
        },
        {
            name: 'Serviços',
            path: 'javascript:abrirWhats()',
            icon: Servicos
        },
        {
            name: 'Sobre Nós',
            path: 'javascript:abrirWhats()',
            icon: Sobre
        },
        {
            name: 'Nosso Time',
            path: '',
            icon: Time
        },
        {
            name: 'Contato',
            path: 'javascript:abrirWhats()',
            icon: Contato
        },
        {
            name: 'Área do Parceiro',
            path: 'https://app1.gerencialcredito.com.br/baraocredsl/default.asp',
            icon: Parceiro
        }
    ]

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <div className="icon-menu"><img src={Logo} /></div>
                {menu.map((i, index) => {
                    const Imagem = i.icon;
                    return (

                        <a href={i.path}>
                            <ListItem button key={i.name}>
                                <ListItemIcon>
                                    <Imagem />
                                </ListItemIcon>
                                <ListItemText primary={i.name} />
                            </ListItem>
                        </a>
                    )
                })}
            </List>
        </Box>
    );

    return (
        <div>
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <MenuIcon className="button-menu" onClick={toggleDrawer(anchor, true)} />
                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </div>
    );
}