import * as React from 'react';
import './style.css';
import { Button, Row, Container, Col } from 'react-bootstrap';
import Nav from '../Nav';
import { BusinessCenter, ArrowForwardIos } from '@material-ui/icons';

import imagemHeader from '../../Imagens/Logo - Branca.png'
import { Link } from 'react-router-dom';

import ModalRender from './modal';
import ModalRenderTutorial from './tutorial';


export default function Header() {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const openModal = () => setOpen(true);
    const [simuladorOpen, setSimuladorOpen] = React.useState(false);
    const [simuladorEntrada, setSimuladorEntrada] = React.useState(true);
    const [valoresApresentacao, setValoresApresentacao] = React.useState(false);
    const [valoresSimulados, setValoresSimulados] = React.useState('5.369,22');
    const [formularioContratacao01, setFormulario01] = React.useState(false);
    const [formularioContratacao02, setFormulario02] = React.useState(false);
    const [formularioContratacao03, setFormulario03] = React.useState(false);
    const [valorSolicitado, setValorSolicitado] = React.useState(null);
    const [mesAniversario, setMesAniversario] = React.useState(null);
    const [nomeCompleto, setNomeCompleto] = React.useState(null);
    const [cpfCliente, setCpfCliente] = React.useState(null);
    const [telefoneCliente, setTelefoneCliente] = React.useState(null);
    const [emailCliente, setEmailCliente] = React.useState(null);
    const [senha, setSenha] = React.useState(null);
    const [senhaConfirmacao, setSenhaConfirmacao] = React.useState(null);
    const [stepS, setStepS] = React.useState(1);

    const handleAlterPage = (nStep) => {
        setStepS(nStep);
    };

    const handleSetNext = (step) => {
        switch (step) {
            case 1:
                if (valorSolicitado === null || mesAniversario === null) {
                    alert("Preencha o valor solicitado e mês de aniversário para prosseguir com a simulação!");
                    return;
                }

                handleSimulador();
                break;
            case 2:
                setValoresApresentacao(false);
                setFormulario01(true);
                break;

            case 3:
                if (nomeCompleto !== null && cpfCliente !== null && telefoneCliente !== null && emailCliente !== null) {
                    setFormulario01(false);
                    setFormulario02(true);
                    return;
                }

                alert("Prencha todos os campos para prosseguir");
                break;

            case 4:
                if ((senha !== null || senhaConfirmacao !== null) && (senha === senhaConfirmacao)) {
                    handleSaveInfo();
                    return;
                }

                alert("Atenção! as senhas devem ser iguais");
                return;

                /*Salvar Dados*/
                break;


            default:
                break;
        }
    };

    const handleSimulador = () => {
        setSimuladorEntrada(false);
        setValoresApresentacao(true);
        const valordeSimulacao = parseFloat(parseInt(valorSolicitado) * 0.55).toFixed(2);
        setValoresSimulados(valordeSimulacao);
    };

    const handleSaveInfo = () => {

        const form = new FormData();
        form.append("saldoFGTS", valorSolicitado);
        form.append("mesAniversario", mesAniversario);
        form.append("nome", nomeCompleto);
        form.append("CPF", cpfCliente);
        form.append("telefone", telefoneCliente);
        form.append("email", emailCliente);
        form.append("senha", senha);
        form.append("operacao", "FGTS");

        fetch('https://api-barao.herokuapp.com/insert/form', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify({
                saldoFGTS: valorSolicitado,
                mesAniversario: mesAniversario,
                nome: nomeCompleto,
                CPF: cpfCliente,
                telefone: telefoneCliente,
                email: emailCliente,
                senha: senha,
                operacao: "FGTS",
                liberado: valoresSimulados
            })
        }).then(f => {
            alert('Sua solicitação foi processada, aguarde que entraremos em contato para prosseguir com a digitação.');
            localStorage.setItem('authenticated', true);
            localStorage.setItem('userPortal', JSON.stringify({
                nome: nomeCompleto,
                perfil: 'Operador',
                CPF: cpfCliente
            }))
            window.location.assign("https://portal.baraocredbrasil.com.br/");
            setSimuladorOpen(false);
            setFormulario02(false);
            setSimuladorEntrada(true);

        }).catch(f => {
            console.log(f);
        });
    };


    const renderCardSimulador = () => {
        return (
            <>
                <Col data-aos="zoom-in-right" xl={5} lg={5} md={6} className="card-simulator">
                    <Col data-aos="zoom-in-right" xl={12} lg={12} md={12} className="">
                        <div className='div-center_'>
                            <div style={{ width: '90%' }}>
                                {simuladorEntrada && (
                                    <>
                                        <Row>
                                            <div className='label-fgts'>
                                                <label>Saldo FGTS:</label>
                                            </div>
                                            <Col>
                                                <span className='money-presentation'>R$</span>
                                            </Col>
                                            <Col xl={10} lg={10} md={10}>
                                                <input onChange={(e) => setValorSolicitado(e.target.value)} className='input-fgts' type="text" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className='label-fgts'>
                                                <label>Mês de Aniversário:</label>
                                            </div>
                                            <Col>
                                                <input onChange={(e) => setMesAniversario(e.target.value)} type="date" className='input-fgts' />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <a href='#' onClick={() => setOpen(true)}>Não conheço o meu <b>Saldo</b>, preciso de ajuda!</a>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Button onClick={() => handleSetNext(1)} className='button-fgts'>SIMULAR</Button>
                                            </Col>
                                        </Row>
                                    </>
                                )}

                                {valoresApresentacao && (
                                    <>
                                        <Row>
                                            <div className='label-fgts'>
                                                <label>Parabens! foi liberado o valor de:</label>
                                            </div>
                                            <Col>
                                                <span className='money-presentation'>R$ <span className='valueOfSimulation'>{valoresSimulados}</span></span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <p style={{ textAlign: 'left' }}>
                                                Deseja contratar?
                                                o processo é rápido e descomplicado, em até 24 horas o valor está em sua conta
                                            </p>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button onClick={() => handleSetNext(2)} className='button-fgts'>CONTRATAR</Button>
                                            </Col>
                                        </Row>
                                    </>
                                )}

                                {formularioContratacao01 && (
                                    <>
                                        <Row>
                                            <div className='label-fgts'>
                                                <label>Nome Completo:</label>
                                            </div>
                                            <Col>
                                                <input onChange={(e) => setNomeCompleto(e.target.value)} type="text" className='input-fgts' />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className='label-fgts'>
                                                <label>CPF:</label>
                                            </div>
                                            <Col>
                                                <input onChange={(e) => setCpfCliente(e.target.value)} type="number" className='input-fgts' />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className='label-fgts ol'>
                                                    <label>Telefone de Contato:</label>
                                                </div>
                                                <input onChange={(e) => setTelefoneCliente(e.target.value)} type="number" className='input-fgts' />
                                            </Col>
                                            <Col>
                                                <div className='label-fgts ol'>
                                                    <label>E-mail:</label>
                                                </div>
                                                <input onChange={(e) => setEmailCliente(e.target.value)} type="email" className='input-fgts' />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button onClick={() => handleSetNext(3)} className='button-fgts'>PROSSEGUIR</Button>
                                            </Col>
                                        </Row>
                                    </>
                                )}

                                {formularioContratacao02 && (
                                    <>
                                        <Row>
                                            <div className='label-fgts'>
                                                <label>Agora iremos criar seu acesso para acompanhar o andamento da solicitação. Informe os dados abaixo:</label>
                                            </div>


                                            <Col>
                                                <div className='label-fgts'>
                                                    <label>Senha:</label>
                                                </div>
                                                <input onChange={(e) => setSenha(e.target.value)} type="password" className='input-fgts' />
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col>
                                                <div className='label-fgts'>
                                                    <label>Confirme sua senha:</label>
                                                </div>
                                                <input onChange={(e) => setSenhaConfirmacao(e.target.value)} type="password" className='input-fgts' />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button onClick={() => handleSetNext(4)} className='button-fgts'>FINALIZAR</Button>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </div>
                        </div>
                    </Col>
                </Col>
            </>
        );
    };

    return (
        <>
            <ModalRenderTutorial open={open} handleCloseModal={handleClose} step={stepS} handleAlterPage={handleAlterPage} />
            {/* <ModalRender open={open} handleCloseModal={handleClose} /> */}
            <Nav />
            <section className="section-header">
                <Container>
                    <Row className="header-row">
                        {simuladorOpen ? renderCardSimulador() : (
                            <Col className="hideinMobile" xl={5} lg={5} md={6}>
                                <Col data-aos="zoom-in-right" xl={12} lg={12} md={12} className="box-title-header">
                                    <div className="header-title">
                                        <div className="title">
                                            A SOLUÇÃO IDEAL PARA VOCÊ
                                        </div>
                                        <p className="white mb-5 p-header">
                                            Na Barão você encontra as taxas mais baixas do mercado,
                                            aliado a Rapidez, Responsabilidade, Qualidade e Segurança.
                                            <br />
                                            <br />
                                            Somos a solução ideal para você organizar as finanças e realziar seus sonhos.
                                        </p>
                                        {/* <Link to="/sobre" className="btn-b btn-lights btn-xl" >Fazer Simulação <ArrowForwardIos /></Link> */}
                                        <a href="#" onClick={() => setSimuladorOpen(true)} className="btn-b btn-lights btn-xl" >
                                            Fazer Simulação <ArrowForwardIos />
                                        </a>
                                    </div>
                                </Col>
                            </Col>
                        )}
                        <Col data-aos="zoom-in-left" xl={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 1 }} md={{ span: 6 }} >
                            <img className="logo" src={imagemHeader} />
                        </Col>
                        <div className="showinMobile" style={{ width: '100%' }}>
                            <Col data-aos="zoom-in-right" xl={12} lg={12} md={12} className="box-title-header">
                                <div className="header-title">
                                    <div className="title">
                                        A SOLUÇÃO IDEAL PARA VOCÊ
                                    </div>
                                    <p className="white mb-5 p-header">
                                        Na Barão você encontra as taxas mais baixas do mercado,
                                        aliado a Rapidez, Responsabilidade, Qualidade e Segurança.
                                        <br />
                                        <br />
                                        Somos a solução ideal para você organizar as finanças e realziar seus sonhos.
                                    </p>
                                    <a href="#" onClick={openModal} className="btn-b btn-lights btn-xl" >
                                        Fazer Simulação <ArrowForwardIos />
                                    </a>
                                </div>
                            </Col>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}
