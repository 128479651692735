import * as React from 'react';
import './style.css';
import { Row, Container, Col } from 'react-bootstrap';
import { ReactPhotoCollage } from "react-photo-collage";

import Foto01 from '../../Imagens/fotos-funcionarios/f-01.jpg'
import Foto02 from '../../Imagens/fotos-funcionarios/f-02.jpg'
import Foto03 from '../../Imagens/fotos-funcionarios/f-03.jpg'
import Foto04 from '../../Imagens/fotos-funcionarios/f-04.jpg'
import Foto05 from '../../Imagens/fotos-funcionarios/f-05.jpg'
import Foto06 from '../../Imagens/fotos-funcionarios/f-06.jpg'
import Foto07 from '../../Imagens/fotos-funcionarios/f-07.jpg'
import Foto08 from '../../Imagens/fotos-funcionarios/f-08.jpg'
import Foto09 from '../../Imagens/fotos-funcionarios/f-09.jpg'
import Foto10 from '../../Imagens/fotos-funcionarios/f-10.jpg'
import Foto11 from '../../Imagens/fotos-funcionarios/f-11.jpg'
import Foto12 from '../../Imagens/fotos-funcionarios/f-12.jpg'


export default function Funcionarios() {
    const setting = {
        width: '80%',
        height: ['450px', '170px'],
        layout: [1, 4],
        photos: [
            { source: Foto01 },
            { source: Foto02 },
            { source: Foto03 },
            { source: Foto04 },
            { source: Foto05 },
            { source: Foto06 },
            { source: Foto07 },
            { source: Foto08 },
            { source: Foto09 },
            { source: Foto10 },
            { source: Foto11 },
            { source: Foto12 },
        ],
        showNumOfRemainingPhotos: true
    };

    return (

        <Container>
            <h1 className="h1">Trabalhamos sempre com total responsabilidade para lhe oferecer a melhor experiência</h1>
            <Row className="text-center mb-5">
                <Col className="col-photo">
                    <ReactPhotoCollage {...setting} />
                </Col>
            </Row>
        </Container>
    )
}