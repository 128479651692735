import React, { useState } from "react";
import './App.css';
import Header from './Componentes/Header';
import Servicos from './Componentes/Servicos';
import Qualidades from './Componentes/Qualidades';
import Funcionarios from './Componentes/Funcionarios';
import Footer from './Componentes/Footer';
import Drawer from './Componentes/Menu';
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import Team from "./Componentes/Team";
import CookieBanner from 'react-cookie-banner';



function App() {
  const [key, setKey] = useState(0);
  const [scrollYSuppressed, setScrollYSuppressed] = useState(false);
  const [scrollXSuppressed, setScrollXSuppressed] = useState(true);
  const [accepted, setAccepted] = useState(false)
  const message = "A Barão Cred utiliza cookies para garantir aos usuários a utilização das funcionalidades do seu site, proporcionando uma melhor experiência de navegação. Ao continuar a navegar no site, você concorda com o uso de cookies."
  const styles = {
    banner: {
      fontFamily: 'Nunito',
      height: 57,
      background: 'rgba(52, 64, 81, 0.88) url(/cookie.png) 20px 50% no-repeat',
      backgroundSize: '30px 30px',
      backgroundColor: '',
      fontSize: '15px',
      fontWeight: 600,
      position: 'absolute',
      width: '100%',
      bottom: 0
    },
    button: {
      border: '1px solid white',
      borderRadius: 4,
      width: 66,
      height: 32,
      lineHeight: '32px',
      background: 'transparent',
      color: 'white',
      fontSize: '14px',
      fontWeight: 600,
      opacity: 1,
      right: 20,
      marginTop: -18
    },
    message: {
      display: 'block',
      padding: '9px 67px',
      lineHeight: 1.3,
      textAlign: 'left',
      marginRight: 244,
      color: 'white'
    },
    link: {
      textDecoration: 'none',
      fontWeight: 'bold'
    }
  }
  return (
    <div className="App">
      <Drawer />
      <CookieBanner
        styles={styles}
        message={message}
        link={<a href='#'>Para mais informações, consulte nossa <b>Política de Privacidade</b></a>}
        buttonMessage='Aceitar'
        dismissOnScroll={false}
        dismissOnClick={false}
        onAccept={() => setAccepted(true)}
      />
      <PerfectScrollbar
        style={{ maxHeight: '100vh' }}
        options={{ suppressScrollY: scrollYSuppressed, suppressScrollX: scrollXSuppressed }}
        onScrollY={(container) => {
          // window.pageYOffset = container.scrollTop - 2000;
          console.log(window.pageYOffset)
          window.atualizar();
        }}
        key={key}
      >
        <div style={{ height: '1600px' }}>
          <Header />
          <Servicos />
          <Qualidades />
          <Funcionarios />
          {/* <Team /> */}
          <Footer />
        </div>
      </PerfectScrollbar >
    </div >
  );
}

export default App;



