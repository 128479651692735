import * as React from 'react';
import './style.css';
import {
    Container,
    Navbar,
    Nav
} from 'react-bootstrap';
import Icons from '../../Imagens/icon.png';
import PropTypes from 'prop-types';

export default function NavBar(props) {

    return (
        <Navbar style={
            {
                backgroundColor: props.cBackground,
                boxShadow: props.shadown ? ' 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)' : 'none',
                position: props.positionAbsolute ? 'absolute' : 'relative'
            }
        } expand="lg" className="navbar-barao">
            <Container>
                <Navbar.Brand href="#home">
                    {/* <img src={Icons} /> */}
                </Navbar.Brand>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="#home">Início</Nav.Link>
                        <Nav.Link href="#link">Serviços</Nav.Link>
                        <Nav.Link href="#link">Sobre Nós</Nav.Link>
                        <Nav.Link href="#link">Nosso Time</Nav.Link>
                        <Nav.Link href="javascript:abrirWhats()">Contato</Nav.Link>
                        <Nav.Link href="https://app1.gerencialcredito.com.br/baraocredsl/default.asp">Área do Parceiro</Nav.Link>
                        <Nav.Link href="https://portal.baraocredbrasil.com.br">Portal do Cliente</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )

}

NavBar.propTypes = {
    cBackground: PropTypes.string,
    shadown: PropTypes.bool,
    positionAbsolute: PropTypes.bool
}

NavBar.defaultProps = {
    cBackground: 'transparent',
    shadown: false,
    positionAbsolute: true
}

