import * as React from 'react';
import './style.css';
import { Button, Row, Container, Col } from 'react-bootstrap';
import Nav from '../Nav';
import { BusinessCenter, ArrowForwardIos } from '@material-ui/icons';
import { Modal, Typography, Box, TextField, Stack } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import './styleModal.css';
import stepImg1 from '../../Imagens/01.png';
import stepImg2 from '../../Imagens/02.png';
import stepImg3 from '../../Imagens/03.png';

export default function ModalRenderTutorial({ open, handleCloseModal, step, handleAlterPage }) {

    const renderImg = () => {
        let imagem = null;
        switch (step) {
            case 1:
                imagem = stepImg1;
                break;
            case 2:
                imagem = stepImg2;
                break;
            case 3:
                imagem = stepImg3;
                break;

            default:
                break;
        }

        return <img src={imagem} alt="" />;
    }

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box className="modalForm">
                <Button onClick={handleCloseModal} className="buttonClose">X</Button>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <div style={{ textAlign: 'center' }}>
                        {renderImg()}
                    </div>
                </Typography>
                <Stack direction="row" style={{ flex: 1, justifyContent: 'center' }} spacing={2}>
                    <div style={{ textAlign: 'center' }}>
                        <Stack direction="row" spacing={2}>
                            <Button onClick={() => handleAlterPage(step - 1)} disabled={step > 1 ? false : true} className="prevbutton">Anterior</Button>
                            <Button onClick={() => handleAlterPage(step + 1)} disabled={step < 3 ? false : true} className="nextbutton">Próximo</Button>
                        </Stack>
                    </div>
                </Stack>
            </Box>
        </Modal>
    )
};