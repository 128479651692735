import * as React from 'react';
import './style.css';
import { Button, Row, Container, Col } from 'react-bootstrap';
import Nav from '../Nav';
import { BusinessCenter, ArrowForwardIos } from '@material-ui/icons';
import { Modal, Typography, Box, TextField, Stack } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import './styleModal.css';

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: '50%',
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
// };

const validationSchema = yup.object({
    foneContato: yup
        .string('Necessário informar um Contato')
        .required('Necessário informar um Contato'),
    nome: yup
        .string('Nome é um campo obrigatório')
        .min(1, '...')
        .required('Nome é um campo obrigatório'),

});

export default function ModalRender({ open, handleCloseModal }) {

    const formik = useFormik({
        initialValues: {
            email: '',
            nome: '',
            cpf: '',
            valorDesejado: '',
            convenio: '',
            foneContato: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const link = `https://baraocredbrasil.com.br/envioEmail.php?email=${formik.values.email}&nome=${formik.values.nome}&cpf=${formik.values.cpf}&valorDesejado=${formik.values.valorDesejado}&convenio=${formik.values.convenio}&foneContato=${formik.values.foneContato}`;
            fetch(link)
                .then(async (response) => {
                    const ret = await response.json();
                    if (ret == "enviado") {
                        alert('Solicitação Enviada, favor aguardar contato');
                        handleCloseModal(false);
                    } else {
                        alert('Houve um erro, entre em contato via WhatsApp');
                        handleCloseModal(false);
                    }
                })
                .catch(async (e) => {
                    console.log(e)
                })
            // alert(JSON.stringify(values, null, 2));
        },
    });

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box className="modalForm">
                <Button onClick={handleCloseModal} className="buttonClose">X</Button>
                <Typography id="modal-modal-title" variant="h4" component="h2">
                    Formulário de Empréstimo
                </Typography>
                <Typography id="modal-modal-title" variant="p" component="h6">
                    Preencha os dados abaixo para que nossos analistas entrem em contato com você!
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <div>
                        <form onSubmit={formik.handleSubmit}>
                            <Stack spacing={2}>
                                <TextField
                                    id="nome"
                                    value={formik.values.nome}
                                    onChange={formik.handleChange}
                                    label="Nome Completo" variant="standard"
                                    error={formik.touched.nome && Boolean(formik.errors.nome)}
                                    helperText={formik.touched.nome && formik.errors.nome}
                                />
                                <TextField
                                    id="cpf"
                                    value={formik.values.cpf}
                                    onChange={formik.handleChange}
                                    label="CPF" variant="standard" />
                                <TextField
                                    id="valorDesejado"
                                    value={formik.values.valorDesejado}
                                    onChange={formik.handleChange}
                                    label="Valor Desejado ( R$ )" variant="standard" />
                                <TextField
                                    id="convenio"
                                    value={formik.values.convenio}
                                    onChange={formik.handleChange}
                                    label="Informe seu Convênio" variant="standard" />
                                <TextField
                                    id="foneContato"
                                    value={formik.values.foneContato}
                                    onChange={formik.handleChange}
                                    label="Telefone para Contato" variant="standard"
                                    error={formik.touched.foneContato && Boolean(formik.errors.foneContato)}
                                    helperText={formik.touched.foneContato && formik.errors.foneContato}
                                />
                                <TextField
                                    id="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    label="Email para Contato"
                                    variant="standard" />

                                <Button style={{ background: '#000', color: '#FFF' }} variant="contained" type="submit">
                                    Solicitar Análise de Crédito
                                </Button>
                            </Stack>
                        </form>
                    </div>
                </Typography>
            </Box>
        </Modal>
    )
};