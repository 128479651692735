import * as React from 'react';
import './style.css';
import { Row, Container, Col } from 'react-bootstrap';
import Qualidade from '../../Imagens/qualidade.png'
import AOS from 'aos';

export default function Qualidades() {
    const qualidades = [
        {
            qualidade: 'Qualidade',
            descricao: 'Nossa empresa vem sempre oferecer as melhores opções de créditos, que se encaixam ao perfil do cliente que procuram nosso produto, garantindo total satisfação',
            imagem: Qualidade
        },
        {
            qualidade: 'Responsabilidade',
            descricao: 'Trabalhamos sempre com total segurança e comprometimento.',
            imagem: Qualidade
        },
        {
            qualidade: 'Rapidez',
            descricao: 'Entregamos um serviço de qualidade com atendimento rápido e sem burocracia.',
            imagem: Qualidade
        },
        {
            qualidade: 'As menores taxas',
            descricao: 'Na Barão você encontra as taxas mais baixas e os maiores prazos do mercado.',
            imagem: Qualidade
        },
    ]

    React.useEffect(() => {
        AOS.init();
    }, [])

    return (

        <section className="section-qualidades">
            <h1 className="h1">Porquê escolher a Barão?</h1>
            <Container>
                <Row data-aos="zoom-in">
                    {qualidades.map(f => {
                        return (
                            <Col xl={3} lg={3} md={12} className="flex-aling " >
                                <img className="imagem-qualidade" src={f.imagem} alt="" />
                                <h3 className="h3 margin-0 mt-4">{f.qualidade}</h3>
                                <p style={{ minHeight: 150 }} className="left-p margin-0 text-center">{f.descricao}</p>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </section>
    )
};