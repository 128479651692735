import logo from '../logo.svg';
import '../App.css';
import './style.css';
import { Link } from 'react-router-dom';
import NavBar from '../Componentes/Nav';
import { Col, Container, Row } from 'react-bootstrap';
import { Breadcrumbs, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/HomeOutlined'
import GrainIcon from '@material-ui/icons/LabelImportant'
import Drawer from '../Componentes/Menu'

function Sobre() {
    return (
        <div className="App">
            <Drawer />
            <NavBar cBackground="#072c4a" shadown={false} positionAbsolute={false} />
            <div className="titulo-page">

                <h1>
                    <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Campanha de Marketing
                </h1>
            </div>
            <section className="section-header-page" />
            <div className="div-container">
                <Breadcrumbs className="bread" aria-label="breadcrumb">
                    <Link to="/" className="bread-link">
                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        Inicio
                    </Link>
                    <Typography
                        sx={{ display: 'flex', alignItems: 'center' }}
                        color="text.primary"
                    >
                        Sobre
                    </Typography>
                </Breadcrumbs>
            </div>

            <div className="mb-3">Todos os direitos reservados - Desenvolvido por RM Dev Solutions</div>

        </div>
    );
}

export default Sobre;
