import * as React from 'react';
import './style.css';
import { Row, Container, Col } from 'react-bootstrap';
import FlatList from 'flatlist-react';



import forcasArmadas from '../../Imagens/forcasArmadas.jpg';
import comissionados from '../../Imagens/business-01.jpg';
import funcionarioPublico from '../../Imagens/business-02.jpg';
import governos from '../../Imagens/business-03.jpg';
import emprestimoAssalariado from '../../Imagens/business-04.jpg';
import debitoConta from '../../Imagens/business-05.jpg';
import creditoImovel from '../../Imagens/imovel.jpg';
import aposentados from '../../Imagens/idosos.jpg';

import ReactCardFlip from 'react-card-flip';

export default function ServicosApp() {



    const [isFlippedS, setFlipped] = React.useState([
        {
            id: 0,
            bol: false
        },
        {
            id: 1,
            bol: false
        },
        {
            id: 2,
            bol: false
        },
        {
            id: 3,
            bol: false
        },
        {
            id: 4,
            bol: false
        },
        {
            id: 5,
            bol: false
        },
        {
            id: 6,
            bol: false
        },
        {
            id: 7,
            bol: false
        },

    ])

    const servicos = [
        {
            titulo: "Forças Armadas e Militares",
            subtitulo: "A Barão Cred Brasil tem facilidades e condições exclusivas para você, profissional da Marinha. Aqui, você conta com uma solução de crédito rápida e fácil, com parcela descontada diretamente da sua folha de pagamento",
            imagem: forcasArmadas,
            posicao: "left"
        },
        {
            titulo: "Comissionados e Contratados pelo Governo",
            subtitulo: "Comprometimento e dedicação para oferecer as melhores condições de crédito consignado a você, Servidor Público.",
            imagem: comissionados,
            posicao: "right"
        },
        {
            titulo: "Funcionário Público",
            subtitulo: "A Barão Cred Brasil tem facilidades e condições exclusivas para você, profissional da Marinha. Aqui, você conta com uma solução de crédito rápida e fácil, com parcela descontada diretamente da sua folha de pagamento",
            imagem: funcionarioPublico,
            posicao: "left"
        },
        {
            titulo: "Governo Federal, Estadual ou Municipal",
            subtitulo: "Se você é Servidor Ativo, Inativo ou Pensionista do Governo Federal, Estadual ou Municipal, conte com a solução de crédito que a Barão Cred Brasil oferece e tenha uma assistência financeira prática, com parcelas descontadas diretamente da sua folha de pagamento.",
            imagem: governos,
            posicao: "right"
        },
        {
            titulo: "Financiamento e Refinanciamento de Imóveis",
            subtitulo: "Para quem é dono de um imóveis esta é a forma mais barata de tomar um empréstimo para pagar outras dívidas com juros mais altos.",
            imagem: creditoImovel,
            posicao: "left"
        },
        {
            titulo: "Aposentados e Pensionistas",
            subtitulo: "A Barão Cred Brasil traz uma linha de crédito exclusiva para você, aposentado ou pensionista, realizar suas conquistas. Conte com um empréstimo de taxas diferenciadas e parcelas descontadas diretamente do seu benefício – tudo o que você precisa para aproveitar o melhor da vida com muita tranquilidade.",
            imagem: aposentados,
            posicao: "right"
        },
        {
            titulo: "Empréstimo para assalariados de empresas privadas",
            subtitulo: "Crédito rápido para quem trabalha com carteira assinada e possui conta corrente com um dos nossos bancos convêniados: Banco do Brasil, Santander, Bradesco e Caixa.",
            imagem: emprestimoAssalariado,
            posicao: "left"
        },
        {
            titulo: "Débito em conta",
            subtitulo: "O débito em conta é um grande aliado. Primeiro por conta do tempo de recebimento (em média 3 dias úteis) e também pela tarifa (consideravelmente menor do que o boleto bancário).",
            imagem: debitoConta,
            posicao: "right"
        }
    ]

    const handleFlip = (idx) => {
        const newObject = []
        isFlippedS.map(f => {
            if (f.id === idx) {
                newObject.push({
                    id: idx,
                    bol: true
                })
            } else {
                newObject.push(f)
            }
        })

        setFlipped(newObject)
        console.log('entrou aqui')

    }

    const handleFlipOff = (idx) => {
        console.log(idx)
        const newObject = []
        isFlippedS.map(f => {
            if (f.id === idx) {
                newObject.push({
                    id: idx,
                    bol: false
                })
            } else {
                newObject.push(f)
            }
        })

        setFlipped(newObject)
        console.log(isFlippedS)
    }

    const renderServico = (f, idx) => {
        return (

            <li className="lista-servico" data-aos="zoom-in-left" key={idx}>
                {f.posicao === 'left' ? (
                    <Row className="mt-5 mb-5">
                        <Col className="flex-col border-left" xl={7} lg={7} md={6}>
                            <h3 className="h3">{f.titulo}</h3>
                            <p className="left-p">{f.subtitulo}</p>
                        </Col>
                        <Col xl={5} lg={5} md={6}>
                            <ReactCardFlip isFlipped={isFlippedS[idx].bol} flipDirection="vertical">
                                <img onMouseEnter={() => handleFlip(idx)} className="imagem-round" src={f.imagem} />
                                <div className="back-flip" onMouseLeave={() => handleFlipOff(idx)}>
                                    A oportunidade está em suas mãos, tire suas dúvidas clicando no botão abaixo
                                    <button onClick={() => window.abrirWhats()} className="btn-saiba-mais">Saiba Mais</button>
                                </div>
                            </ReactCardFlip>
                        </Col>
                    </Row>
                ) : (
                    <Row className="mt-5 mb-5">
                        <Col xl={5} lg={5} md={6}>
                            <ReactCardFlip isFlipped={isFlippedS[idx].bol} flipDirection="vertical">
                                <img onMouseEnter={() => handleFlip(idx)} className="imagem-round" src={f.imagem} />
                                <div className="back-flip" onMouseLeave={() => handleFlipOff(idx)}>
                                    A oportunidade está em suas mãos, tire suas dúvidas clicando no botão abaixo
                                    <button onClick={() => window.abrirWhats()} className="btn-saiba-mais">Saiba Mais</button>
                                </div>
                            </ReactCardFlip>
                        </Col>
                        <Col className="flex-col border-right" xl={7} lg={7} md={6}>
                            <h3 className="h3">{f.titulo}</h3>
                            <p className="left-p">{f.subtitulo}</p>
                        </Col>
                    </Row>
                )}
            </li>
        )
    }

    return (
        <Container>
            <h1 className="h1">Temos a solução ideal para você!</h1>
            <ul style={{ listStyleType: 'none' }}>
                <FlatList
                    list={servicos}
                    renderItem={renderServico}
                />
            </ul>
        </Container>
    )
}