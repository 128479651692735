import * as React from 'react';
import './style.css';
import { Row, Container, Col } from 'react-bootstrap';
import Logo from '../../Imagens/Logo - Branca.png'
import Mark from '@material-ui/icons/Room';
import { BusinessCenter, ArrowForward } from '@material-ui/icons';

import FlatList from 'flatlist-react';

const enderecos = [
    {
        nome: 'Matriz – São luís',
        endereco: 'Av. dos Holandeses, sala 312, Edifício Appiani, Calhau'
    },
    {
        nome: 'Filial – Rua do sol',
        endereco: 'Rua do sol, 615, Centro, São Luís – MA'
    },
    {
        nome: 'Filial – Salvador',
        endereco: 'Av. Estados Unidos, 397, Ed. Cidade do Salvador, 9° andar, sala 908, Comercio Salvador'
    },
    {
        nome: 'Filial- Camaçari, Bahia',
        endereco: 'Rua Costa Pinto, Nº 9, Centro.'
    },
    {
        nome: 'Filial- Piauí',
        endereco: 'Rua Rui Barbosa 68, sala 301, ed Otávio Miranda, centro'
    },
]

const contatos = [
    {
        contato: '(98) 3301-5311',
        tipo: 'Fone'
    },
    {
        contato: '(71) 3041-1950',
        tipo: 'Fone'
    },
    {
        contato: '(86) 3303-8100',
        tipo: 'Fone'
    }
]

const render = (endereco, idx) => {
    return (
        <li key={idx}>
            <div className="mb-3">
                <div><BusinessCenter fontSize="small" /> - {endereco.nome}</div>
                <div><Mark fontSize="small" /> - {endereco.endereco}</div>
                <hr />
            </div>
        </li>
    )
}

const renderContatos = (contato, idx) => {
    return (
        <li key={idx}>
            {contato.contato}
        </li>
    )
}

export default function Footer() {
    return (
        <section className="footer">
            <div className="footer-dev">

                <Container className="line-container">
                    <Row>
                        <Col xs={12} lg={4} md={4} className="flex-col h-350 col-sobre">
                            <h3>A Barão</h3>
                            <p className="left-p text-just">Somos uma empresa que conquista a cada dia um importante espaço como correspondente bancário. Somos formados por profissionais capacitados e experientes no mercado financeiro e com total conhecimento dos serviços prestados.</p>
                        </Col>

                        <Col xs={12} lg={5} md={5} className="flex-col h-550 margin-50" >
                            <h3 className="text-center">Endereços</h3>
                            <ul style={{ listStyleType: 'none' }}>
                                <FlatList
                                    list={enderecos}
                                    renderItem={render}
                                />
                            </ul>
                        </Col>

                        <Col xs={3} lg={3} md={3} className="flex-col h-550 hideOnMobile">
                            <img src={Logo} />
                        </Col>
                    </Row>
                </Container>



                Todos os direitos reservados - Desenvolvido por RM Dev Solutions
            </div>
        </section >
    )
}